/******* <Переменные> ************/
$minWidth: 320px;
$maxWidth: 2000;
$maxWidthContainer: 1210;
$md1: $maxWidthContainer + 12;
$md2: 991.98;
$md3: 767.98;
$md4: 479.98;
$linkAnim: all 0.3s ease 0s;
/******* </Переменные> ************/
/* pythonGenerationPage */
.pythonOnlineProPage {
    position: relative;
    box-sizing: border-box;
    padding-top: 524px;
    overflow: hidden;
    min-width: 320px;
    .project__course__img {
        flex: 0 1 50%;
        height: 400px;
        background-image: url("./img/p2.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50%;
    }
    .button {
        height: 50px;
        line-height: 50px;
        background: #0065ff;
        color: #fff;
        padding: 0 22px;
        border-radius: 24px;
        text-transform: uppercase;
        vertical-align: top;
        transition: $linkAnim;
        &:hover {
            background: #005aad;
        }
    }
    .buttonWrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: flex-start;
    }
    @media (max-width: 1100px) {
        padding-top: 450px;
    }
    @media (max-width: $md3+px) {
        padding-top: 355px;
        .project__course__img {
            flex: 0 1 100%;
            text-align: center;
            background-size: contain;
            background-position: 50%;
            height: 300px;
        }
    }
    @media (max-width: $md4+px) {
        padding-top: 410px;
    }
    @media (max-width: 350px) {
        padding-top: 430px;
    }
}
/* баннер на главной */
.main {
    &__slide {
        &.pythonOnlinePro {
            background: #94b0f7;
            color: #fff;
            .section-inner {
                justify-content: space-between;
            }
            .h1 {
                margin-bottom: 20px;
            }
            .main__slide__text-block {
                width: 50%;
                p {
                    margin-bottom: 0;
                }
                @media (max-width: 1100px) {
                    padding: 50px 20px;
                    width: 100%;
                    position: relative;
                    background: rgba(0, 0, 0, 0.6);
                    text-align: center;
                    .h1 {
                        font-size: 30px;
                        line-height: 1.2;
                    }
                    p {
                        font-size: 18px;
                    }
                    .timerWrapper {
                        max-width: none;
                    }
                }
                @media (max-width: $md3+px) {
                    padding: 20px 20px;
                    .h1 {
                        font-size: 25px;
                    }
                    p {
                    }
                }
            }
            .main__slide__background-block {
                position: relative;
                left: 0;
                bottom: 0;
                img {
                    height: auto;
                    object-fit: contain;
                    max-width: 100%;
                }
                @media (max-width: 1100px) {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    max-height: 100%;
                    max-width: 100%;
                    left: 0;
                    bottom: 0;
                    background: url("./img/python_stp_header.png") no-repeat bottom;
                    background-size: auto 80%;
                    img {
                        display: none;
                    }
                }
            }
        }
    }
}
.pythonOnlinePro__bottom_text {
    margin: 30px auto 0 auto;
}
