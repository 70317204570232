@import "ui";
/* баннер на главной */
.main {
  &__slide {
    @media (max-width: 1024px) {
      top: 60px;
    }
  }
}
/* contentWrapper */
.contentWrapper {
  position: relative;
  z-index: 2;
  background: #fff;
}
/* about */
.about {
  .section-inner {
    padding: 95px 40px 60px;
    box-sizing: border-box;
    width: 100%;
    max-width: 1104px;
    margin: 0 auto;
    @media (max-width: $md2+px) {
      padding: 50px 20px;
    }
    @media (max-width: $md3+px) {
      padding: 20px 20px;
    }
  }
  &__text {
    font-size: 17px;
    line-height: 1.4;
    color: #000;
    width: 70%;
    text-align: center;
    margin: 0 auto;
    a {
      color: #007dcc;
      text-decoration: none;
      -webkit-transition: all .5s ease-out;
      transition: all .5s ease-out;
      font-weight: 600;
      @media (max-width: $md2+px) {
        color: #0a4066;
      }
    }
    @media (max-width: $md2+px) {
      width: 90%;
    }
    @media (max-width: $md3+px) {
      font-size: 16px;
    }
  }
  &__img {
    text-align: center;
    height: 80px;
    margin-bottom: 60px;
    img {
      max-width: 100%;
      max-height: 80px;
    }
  }
}
/* target */
.target {
  display: block;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  background: #fff;
  z-index: 3;
  .section-inner {
    width: 100%;
    padding: 105px 40px 125px;
    max-width: 1104px;
    margin: 0 auto;
    text-align: center;
    box-sizing: border-box;
  }
  &-bordered {
    .section-inner {
      box-shadow: 0 0 20px 0 rgba(214,211,214,.5);
    }
  }
  &__icon {
    text-align: center;
    margin-bottom: 32px;
    span {
      display: inline-block;
      width: 150px;
      height: 135px;
      line-height: 135px;
      img {
        width: 100%;
        max-height: 100%;
        vertical-align: middle;
      }
    }
  }
  &__text {
    font-size: 17px;
    line-height: 1.4;
    color: #000;
    .h2 {
      font-size: 35px;
      color: #000;
      line-height: 1.2;
      margin-bottom: 20px;
      font-weight: 400;
    }
    a {
      color: #008de7;
      transition: all .5s ease-out;
      text-decoration: none;
      &:hover {
        opacity: .7;
      }
    }
  }
  @media (max-width: $md2) {
    &__text {
      .h2 {
        font-size: 25px;
        line-height: 1.2;
        margin-bottom: 10px;
      }
    }
  }
}
/* course__includes */
.course__includes {
  background: #fff;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  color: #000;
  .section-inner {
    box-sizing: border-box;
    width: 100%;
    max-width: 980px;
    margin: 0 auto;
    .text-center {
      p {
        margin-bottom: 10px;
      }
    }
  }
  &__wrapper {
    width: 100%;
    padding: 0 60px;
    box-sizing: border-box;
    .project_in-number {
      margin-bottom: 50px;
    }
    @media (max-width: $md3+px) {
      padding: 30px 20px;
      background: none;
    }
  }
  &__bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    .button {
      margin-right: 5px;
    }
    &__img {
      margin-left: 5px;
      width: 185px;
      img {
        max-width: 100%;
        display: inline-block;
      }
    }
  }
}
/* project_in-number */
.project_in-number {
  width: 100%;
  padding: 0 60px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  .stats {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 17px;
    line-height: 1.2;
    color: #000;
    font-weight: 600;
    max-width: 250px;
    span {
      display: block;
      text-align: center;
      color: #0065ff;
      font-size: 70px;
      line-height: 1;
      font-weight: 400;
      font-family: bebas-neue, sans-serif;
    }
    @media (max-width: $md3+px) {
      margin: 0 auto;
    }
  }
  &__column {
    flex: 0 1 25%;
    justify-content: center;
    margin-bottom: 20px;
    @media (max-width: $md2+px) {
      flex: 0 1 33.3333%;
    }
    @media (max-width: $md3+px) {
      flex: 0 1 50%;
    }
    @media (max-width: $md4+px) {
      flex: 0 1 100%;
    }
  }
  @media (max-width: $md3+px) {
    justify-content: center;
  }
}
/* project__course */
.project__course {
  .section-inner {
    width: 100%;
    padding: 60px 90px;
    box-sizing: border-box;
    max-width: 1104px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .h2 {
    font-size: 35px;
    line-height: 1.2;
    color: #000;
    margin-bottom: 20px;
    font-weight: 700;
  }
  &__img {
    flex: 0 1 50%;
    height: 400px;
    background-image: url(img/python_stepik_character2.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  &__course-text {
    flex: 0 1 40%;
    .subhead {
      font-size: 17px;
      color: #000;
      line-height: 1.4;
      margin-bottom: 35px;
    }
    ul {
      list-style: none;
      li {
        padding-left: 38px;
        background: url("img/stick2.svg") 7px 6px no-repeat;
        background-size: 18px auto;
        font-size: 17px;
        line-height: 1.4;
        color: #000;
        & + li {
          margin-top: 20px;
        }
      }
    }
  }
  @media (max-width: $md2+px) {
    .section-inner {
      padding: 50px 20px;
    }
    .h2 {
      font-size: 25px;
      line-height: 1.2;
      margin-bottom: 10px;
    }
  }
  @media (max-width: $md3+px) {
    &__img {
      flex: 0 1 100%;
      text-align: center;
      background-size: contain;
      background-position: center;
      height: 300px;
    }
    &__course-text {
      height: auto;
      flex: 0 1 100%;
      margin-bottom: 40px;
      text-align: center;
    }
  }
}
/* reviews */
.reviews {
  padding: 40px 0 50px;
  .section-inner {
    width: 100%;
    padding: 0 40px;
    max-width: 1104px;
    margin: 0 auto;
    text-align: center;
    box-sizing: border-box;
    position: relative;
  }
  .h2 {
    margin-bottom: 30px;
  }
  .subhead {
    font-size: 17px;
    line-height: 1.4;
    color: #000;
    text-align: center;
    max-width: 90%;
    margin: 0 auto 25px;
  }
  .review {
    &__owner {
      font-size: 14px;
      line-height: 22px;
      color: #000;
      font-weight: 600;
      text-align: center;
      span {
        display: block;
        font-size: 17px;
        line-height: 22px;
        color: #8a8a8a;
        font-style: italic;
        margin-top: -5px;
        font-weight: 400;
      }
    }
    &__img {
      width: 110px;
      height: 110px;
      text-align: center;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 auto 13px;
      img {
        max-width: 100%;
        width: 110px;
        height: 110px;
        display: inline-block;
        vertical-align: top;
      }
    }
  }
}
/* offer */
.offer {
  width: 100%;
  background: #fca228;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  .section-inner {
    padding: 30px 20px;
    box-sizing: border-box;
    width: 100%;
    max-width: 1104px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    @media (max-width: $md3+px) {
      flex-direction: column-reverse;
    }
  }
  &__text {
    font-size: 21px;
    line-height: 1.2;
    color: #fff;
    text-align: center;
    flex: 0 0 50%;
  }
  &__img {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
    img {
      max-width: 100%;
      max-height: 40vh;
    }
    @media (max-width: $md3+px) {
      margin-bottom: 20px;
      img {
        width: 150px;
      }
    }
  }
}
/* bottom-text */
.bottom-text {
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 1.2;
  color: #848383;
  text-align: center;
  a {
    color: #00a0f5;
    border-bottom: 1px solid #00a0f5;
    transition: $linkAnim;
    &:hover {
      border-color: #fff;
    }
  }
}
/* requirements */
.requirements {
  .section-inner {
    width: 100%;
    padding: 90px 65px 60px;
    max-width: 1104px;
    margin: 0 auto;
    font-size: 0;
    line-height: 0;
    text-align: center;
    box-sizing: border-box;
  }
  .h2 {
    font-size: 34px;
    color: #000;
    line-height: 1.2;
    margin-bottom: 50px;
    font-weight: normal;
    @media (max-width: 991px) {
      margin-bottom: 37px;
    }
  }
  &__block {
    display: inline-block;
    width: 33.33333333%;
    padding: 0 25px;
    margin: 0 0 45px;
    vertical-align: top;
    box-sizing: border-box;
    &:nth-of-type(2) .requirements__img img {
      height: 30px;
    }
  }
  &__img {
    height: 35px;
    line-height: 35px;
    margin-bottom: 20px;
    img {
      display: inline-block;
      vertical-align: bottom;
      max-height: 100%;
    }
  }
  &__text {
    font-size: 14px;
    line-height: 1.4;
    color: #000;
    text-align: left;
    &__head {
      font-size: 20px;
      line-height: 1.2;
      margin-bottom: 12px;
    }
    a {
      color: #0096ff;
      text-decoration: none;
      transition: all 0.5s ease-out;
      &:hover {
        color: #0a4066;
      }
    }
    @media (max-width: 1200px) {
      text-align: center;
    }
  }
}
/* FAQ */
.FAQ {
  display: block;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 50px 60px 0;
  .section-inner {
    box-sizing: border-box;
    width: 100%;
    max-width: 980px;
    margin: 0 auto;
  }
}
