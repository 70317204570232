/******* Переменные ************/
$minWidth: 320px;
$maxWidth: 2000;
$maxWidthContainer: 1210;
$md1: $maxWidthContainer + 12;
$md2: 991.98;
$md3: 767.98;
$md4: 479.98;
$linkAnim: all 0.3s ease 0s;
/*******************************/
/* общие стили */
img {
  max-width: 100%;
}
.h2 {
  margin-bottom: 50px;
  font-size: 35px;
  line-height: 50px;
  font-weight: 700;
  text-align: center;
  @media (max-width: $md3+px) {
    font-size: 25px;
    line-height: 1.2;
    margin-bottom: 37px;
  }
}
/* button */
.button {
  display: inline-block;
  padding: 1px 40px 0;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.15);
  height: 54px;
  line-height: 52px;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  transition: all 0.5s ease-out, background 0.5s ease-out;
  border: 0 none;
  min-width: 180px;
  cursor: pointer;
  &:hover {
    color: rgba(255, 255, 255, 0.3);
  }
}
/* баннер на главной */
.main {
  &__slide {
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 80px;
    left: 0;
    &__text-block {
      position: relative;
      z-index: 2;
      top: 0;
      .h1 {
        font-size: 48px;
        line-height: 48px;
        font-weight: 600;
        color: #fff;
      }
      p {
        font-size: 23px;
        line-height: 1.4;
        color: #fff;
        font-weight: 400;
      }
      .buttonWrapper {
        padding-top: 30px;
        margin-bottom: 20px;
      }
    }
    &__background-block {
      position: absolute;
      bottom: 1%;
      left: 42%;
      z-index: 1;
      text-align: right;
      width: 50%;
      height: 90%;
      img {
        height: 435px;
        object-fit: contain;
      }
    }
    .section-inner {
      box-sizing: border-box;
      padding: 0 40px;
      width: 100%;
      max-width: 1210px;
      margin: 0 auto;
      min-height: 450px;
      display: flex;
      align-items: center;
      position: relative;
      @media (max-width: 1100px) {
        padding: 0;
        min-height: auto;
      }
    }
  }
}
/* slider__includes */
.slider__includes {
  font-size: 0;
  line-height: 0;
  text-align: center;
  .slide-block {
    width: 33.3333%;
    vertical-align: top;
    display: inline-block;
    margin-bottom: 30px;
    .slide {
      width: 245px;
      margin: 0 auto;
      text-align: center;
      font-size: 18px;
      line-height: 1.2;
      .slide_img {
        width: 100%;
        height: 78px;
        line-height: 78px;
        vertical-align: bottom;
        margin-bottom: 22px;
        img {
          display: inline-block;
          max-height: 78px;
        }
        .num {
          display: block;
          text-align: center;
          font-size: 40px;
          font-weight: bold;
          color: #000;
        }
      }
      .slide_head {
        font-size: 17px;
        line-height: 1.2;
        color: #535353;
        text-align: center;
        min-height: 50px;
      }
      @media (max-width: $md4+px) {
        width: 100%;
      }
    }
  }
  .slick-dots {
    margin-top: 0;
  }
}
/* share_buttons_block */
.share_buttons_block {
  .section-inner {
    width: 100%;
    max-width: 1104px;
    padding: 50px 30px;
    box-sizing: border-box;
    margin: 0 auto;
    text-align: center;
  }
  .Demo__some-network {
    vertical-align: top;
    display: inline-block;
    margin-right: 10px;
    text-align: center;
    &__share-count {
      display: inline-flex;
      justify-content: center;
      white-space: nowrap;
      overflow: visible;
      width: 0;
      margin-top: 3px;
      font-size: 12px;
    }
    &__share-button {
      cursor: pointer;
      &:hover:not(:active) {
        opacity: 0.75;
      }
    }
    &__custom-icon {
      width:32px;
      height:32px;
    }
  }
}
