/******* <Переменные> ************/
$minWidth: 320px;
$maxWidth: 2000;
$maxWidthContainer: 1210;
$md1: $maxWidthContainer + 12;
$md2: 991.98;
$md3: 767.98;
$md4: 479.98;
$linkAnim: all 0.3s ease 0s;
/******* </Переменные> ************/
/* pythonGenerationPage */
.girlsDoItPage {
  position: relative;
  box-sizing: border-box;
  padding-top: 530px;
  overflow: hidden;
  min-width: 320px;
  .button {
    height: 50px;
    line-height: 50px;
    background: #0c6e93;
    color: #fff;
    padding: 0 22px;
    border-radius: 24px;
    text-transform: uppercase;
    vertical-align: top;
    transition: $linkAnim;
    &:hover {
      background: #005aad;
    }
  }
  @media (max-width: 1100px) {
    padding-top: 410px;
  }
  @media (max-width: $md3+px) {
    padding-top: 344px;
  }
  @media (max-width: $md4+px) {
    padding-top: 410px;
  }
  @media (max-width: 350px) {
    padding-top: 371px;
  }
}
/* баннер на главной */
.main {
  &__slide {
    &.girlsDoIt__main__slide {
      background: #e14e6b;
      color: #fff;
      .h1 {
        margin-bottom: 20px;
      }
      .main__slide__text-block {
        width: 500px;
        p {
          margin-bottom: 0;
        }
        @media (max-width: 1100px) {
          padding: 50px 20px;
          width: 100%;
          position: relative;
          background: rgba(0,0,0,.6);
          text-align: center;
          .h1 {
            font-size: 30px;
            line-height: 1.2;
          }
          p {
            font-size: 18px;
          }
        }
        @media (max-width: $md3+px) {
          padding: 20px 20px;
          .h1 {
            font-size: 25px;
          }
          p {

          }
        }
      }
      .main__slide__background-block {
        right: 0;
        bottom: 0;
        width: 50%;
        left: auto;
        height: 100%;
        img {
          max-width: none;
          height: 100%;
          object-fit: inherit;
        }
        @media (max-width: 1100px) {
          height: 100%;
          width: 100%;
          max-height: 100%;
          max-width: 100%;
          left: 0;
          bottom: 0;
          background: url("./img/girl.png") no-repeat right bottom;
          background-size: contain;
          img {
            display: none;
          }
        }
        @media (max-width: $md4+px) {
          background-size: auto 100%;
        }
      }
      @media (max-width: 1024px) {
        .timerWrapper {
         max-width: 100%;
        }
      }
    }
  }
}
/* girlsDoIt__includes */
.girlsDoIt__includes {
  padding: 50px 0;
  .stats span {
    font-size: 65px;
    color: #0c6e93;
    span {
      font-size: 20px;
      display: inline;
    }
  }
}
/* girlsDoIt__course */
.girlsDoIt__course {
  p {
    font-size: 17px;
    line-height: 1.4;
    color: #000;
  }
  .project__course__course-text ul li {
    background: url("../assets/img/stick2.svg") 7px 6px no-repeat;
    background-size: 18px auto;
  }
  .project__course__img {
    background-image: url("../assets/img/working-process.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
  }
}
/* girlsDoIt__target2 */
.girlsDoIt__target2 {
  .section-inner {
    padding: 125px 40px;
    @media (max-width: $md2) {
      padding: 50px 20px;
    }
  }
  .text-center {
    margin-top: 20px;
  }
  .girlsDoIt__target_bottom-text {
    margin-top: 40px;
  }
}
/* girlsDoIt__reviews */
.girlsDoIt__reviews {
  .review {
    &__owner {
      font-size: 19px;
      line-height: 1.2;
      span {
        font-size: 16px;
        line-height: 1.2;
        margin-top: 0;
      }
    }
  }
}

