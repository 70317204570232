/* .konkursCode-slide */
.konkursCode-slide {
  width: 100%;
  background: #00acb4;
  position: fixed;
  z-index: 1;
  top: 80px;
  left: 0;
  color: #ffffff;
  .text-block {
    width: 510px;
    .buttonWrapper {
      margin-bottom: 30px;
    }
    .button {
      background: #ffc513;
      &:hover {
        background: #e7a845;
        color: #ffffff;
      }
    }
  }
  .background-block {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    width: 65%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    img {
      height: 90%;
    }
  }
  .buttonWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
  }
  .timerWrapper {
    font-weight: bold;
  }
  @media (max-width: 1100px) {
    .text-block {
      width: 100%;
    }
  }
}
/* pythonPage-about */
.konkursCode-about.about {
  .section-inner {
    padding: 60px 40px 25px;
  }
  .about__img {
    height: 200px;
    margin-bottom: 0;
    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
  .about__text {
    p {
      margin-top: 15px;
    }
  }
}
/* .konkursCode-offer */
.konkursCode-offer {
background: #00acb4;
&-text {
	font-size: 21px;
	font-weight: normal;
	color: #ffffff;
	p {
	& + p {
		margin-top: 15px;
	}
	}
}
}
/* konkursCode-objective */
.konkursCode-objective {
  .section-inner {
    width: 100%;
    padding: 90px 65px 60px;
    max-width: 1104px;
    margin: 0 auto;
    font-size: 0;
    line-height: 0;
    text-align: center;
    box-sizing: border-box;
  }
  .objective-block {
    display: inline-block;
    width: 50%;
    margin-bottom: 40px;
    vertical-align: top;
  }
  .objective-block-inner {
    width: 215px;
    margin: 0 auto;
  }
  .objective_block_img {
    width: 50px;
    height: 50px;
    line-height: 50px;
    margin: 0 auto 30px;
    vertical-align: bottom;
    img {
      max-width: 100%;
    }
  }
  .objective-block-inner {
    width: 100%;
    max-width: 300px;
  }
  a {
    &:hover {
      color: #00a0f5;
      opacity: .7;
    }
  }
  @media (max-width: 991px) {
    .section-inner {
      padding: 50px 20px;
    }
  }
  @media (max-width: 900px) {
    .objective-block {
      width: 100%;
    }
  }
}
/* .konkursCode-includes */
.konkursCode-includes {
  .includes {
    .button {
      background: #ffc513;
      &:hover {
        background: #e7a845;
        color: #ffffff;
      }
    }
  }
}
/* first_code-course */
.first_code-course.konkurs {
  .course-text li {
    background: url("../assets/img/stick.svg") 0 6px no-repeat;
    background-size: 18px auto;
  }
}
/* .konkursCode-FAQ */
.konkursCode-FAQ, .konkursCode-target-audience {
  .button {
    background: #ffc513;
    &:hover {
      background: #e7a845;
      color: #ffffff;
    }
  }
}
// konkursCode__target
.konkursCode__target {
	ol {
		li {
			list-style-position: inside;
		}
	}
}
.konkursCode-target-audience {
	.button {
	  background: #ffc513;
	  margin-top: 30px;
	  height: 50px;
	  line-height: 50px;
	  padding: 0 22px;
	  border-radius: 24px;
	  cursor: pointer;
	  &:hover {
		background: #e7a845;
		color: #ffffff;
	  }
	}
}
.first_code-reviews._scratch .section-inner {
	padding: 60px 40px 0;
}
.scratchForm {
  background: #00acb4;
  padding: 40px 0 20px;
  margin: 0 0 20px 0;
  .h2 {
    margin-bottom: 24px;
    color: #ffffff;
  }
  .form_contact {
    padding-bottom: 0;
  }
  .help-form {
    //background: #00acb4;
    box-shadow: none;
  }
}
