@import url("https://use.typekit.net/snj1ozu.css");
* {
    margin: 0;
    padding: 0;
}
*:focus {
    outline: none !important;
}
html,
body {
    height: 100%;
    min-height: 100%;
}
body {
    position: relative;
    color: #fff;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    line-height: 1.2;
}
ul {
    list-style: none;
}
.clear:after {
    display: table;
    clear: both;
    content: "";
}
a {
    cursor: pointer;
    transition: all 0.5s ease-out;
    color: #fff;
    text-decoration: none;
}
a:hover {
    text-decoration: none;
}
video {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 1;
    transform: translateX(-50%) translateY(-50%);
    background-size: cover;
    transition: 1s opacity;
}
.button {
    display: inline-block;
    padding: 1px 40px 0;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.15);
    height: 54px;
    line-height: 52px;
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
    transition: all 0.5s ease-out, background 0.5s ease-out;
    border: 0 none;
}
.button:hover {
    background: rgba(255, 255, 255, 0.3);
}
.input {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #d1d1d1;
    padding: 0 14px;
    color: #7f7f7f;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 38px;
    height: 40px;
}
.input:focus {
    border-color: #000;
}
.textarea {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #d1d1d1;
    padding: 10px 14px;
    color: #7f7f7f;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    min-height: 150px;
    resize: vertical;
}
.textarea:focus {
    border-color: #000;
}
.br-20 {
    display: block;
    width: 100%;
    box-sizing: border-box;
    height: 20px;
}
.br-25 {
    display: block;
    width: 100%;
    box-sizing: border-box;
    height: 25px;
}
.br-30 {
    display: block;
    width: 100%;
    box-sizing: border-box;
    height: 30px;
}
.br-80 {
    display: block;
    width: 100%;
    box-sizing: border-box;
    height: 80px;
}
.text-center {
    text-align: center;
}

#root {
    display: flex;
    min-height: 100%;
    flex-direction: column;
}
