/******* Переменные ************/
$minWidth: 320px;
$maxWidth: 2000;
$maxWidthContainer: 1210;
$md1: $maxWidthContainer + 12;
$md2: 991.98;
$md3: 767.98;
$md4: 479.98;
$linkAnim: all 0.3s ease 0s;
/*******************************/
.cosmosLayout__target {
  .section-inner {
    padding: 125px 40px;
  }
  @media (max-width: $md2) {
    padding: 50px 20px;
  }
}
