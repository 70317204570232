/******* <Переменные> ************/
$minWidth: 320px;
$maxWidth: 2000;
$maxWidthContainer: 1210;
$md1: $maxWidthContainer + 12;
$md2: 991.98;
$md3: 767.98;
$md4: 479.98;
$linkAnim: all 0.3s ease 0s;
/******* </Переменные> ************/
/* pythonGenerationPage */
.pythonGenerationPage {
  position: relative;
  box-sizing: border-box;
  padding-top: 524px;
  overflow: hidden;
  min-width: 320px;
  .button {
    height: 50px;
    line-height: 50px;
    background: #0065ff;
    color: #fff;
    padding: 0 22px;
    border-radius: 24px;
    text-transform: uppercase;
    vertical-align: top;
    transition: $linkAnim;
    &:hover {
      background: #005aad;
    }
  }
  @media (max-width: 1100px) {
    padding-top: 450px;
  }
  @media (max-width: $md3+px) {
    padding-top: 355px;
  }
  @media (max-width: $md4+px) {
    padding-top: 410px;
  }
  @media (max-width: 350px) {
    padding-top: 430px;
  }
}
/* баннер на главной */
.main {
  &__slide {
    &.pythonGeneration {
      background: #fca228;
      color: #fff;
      .h1 {
        margin-bottom: 20px;
      }
      .main__slide__text-block {
        width: 500px;
        p {
          margin-bottom: 0;
        }
        @media (max-width: 1100px) {
          padding: 50px 20px;
          width: 100%;
          position: relative;
          background: rgba(0,0,0,.6);
          text-align: center;
          .h1 {
            font-size: 30px;
            line-height: 1.2;
          }
          p {
            font-size: 18px;
          }
          .timerWrapper {
            max-width: none;
          }
        }
        @media (max-width: $md3+px) {
          padding: 20px 20px;
          .h1 {
            font-size: 25px;
          }
          p {

          }
        }
      }
      .main__slide__background-block {
        img {
          max-width: none;
          height: 435px;
          object-fit: contain;
        }
        @media (max-width: 1100px) {
          height: 100%;
          width: 100%;
          max-height: 100%;
          max-width: 100%;
          left: 0;
          bottom: 0;
          background: url("./img/new-people.png") no-repeat bottom;
          background-size: auto 80%;
          img {
            display: none;
          }
        }
      }
    }
  }
}
.pythonGen__bottom_text {
  margin: 30px auto 0 auto;
}
/* pythonGen__share */
.pythonGen__share {}

