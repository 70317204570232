.reportPage {
    padding-top: 80px;
    color: #494949;
    text-align: center;
}
.head_slide {
    width: 100%;
    height: 300px;
    position: fixed;
    top: 80px;
    left: 0;
    z-index: 1;
    background: #fff;
    display: flex;
    align-items: center;
}
.container {
    width: 100%;
    max-width: 1031px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
    position: relative;
}
.wrapper {
    width: 100%;
    background: #fff;
    position: relative;
    z-index: 2;
    margin-top: 300px;
    padding-bottom: 60px;
}
article {
    font-size: 16px;
    line-height: 24px;
    color: #494949;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    padding-top: 50px;
    text-align: left;
}
article p {
    margin-bottom: 25px;
}
article blockquote {
    box-sizing: border-box;
    padding: 25px 160px 60px;
    text-align: center;
    font-size: 36px;
    line-height: 1.2;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
}
.gallery {
    position: relative;
    background: #fff;
    z-index: 2;
    color: #fff;
}
.gallery .section-inner {
    padding: 60px 40px 20px;
    box-sizing: border-box;
    width: 100%;
    max-width: 1070px;
    margin: 0 auto;
    text-align: center;
    font-size: 0;
    line-height: 0;
}
.gallery .h2 {
    font-size: 34px;
    color: #000;
    line-height: 1.2;
    margin-bottom: 50px;
    font-weight: normal;
}
.videoBlock {
    width: 100%;
    max-width: 1031px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
    position: relative;
}
.videoBlock iframe {
    width: 560px;
    height: 315px;
    margin: 0 auto;
}
.videoBlock .h2 {
    font-size: 34px;
    color: #000;
    line-height: 1.2;
    margin-bottom: 50px;
    font-weight: normal;
}
@media (max-width: 1024px) {
    .reportPage {
        padding-top: 60px;
    }
    .head_slide {
        top: 60px;
    }
}