/******* <Переменные> ************/
$minWidth: 320px;
$maxWidth: 2000;
$maxWidthContainer: 1210;
$md1: $maxWidthContainer + 12;
$md2: 991.98;
$md3: 767.98;
$md4: 479.98;
$linkAnim: all 0.3s ease 0s;
/******* </Переменные> ************/
/* girlsDoIt__includes */
.pythonPage {
  .buttonWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
  }
}
.python__includes {
  padding: 50px 0 0;
  .stats span {
    font-size: 65px;
    color: #0065ff;
    span {
      font-size: 20px;
      display: inline;
    }
  }
  .button {
	  margin-top: 30px;
  }
}
/* pythonPage__geography */
.pythonPage__geography {
  padding: 60px 0 0;
  text-align: center;
}
/* pythonPage-about */
.pythonPage-about.about {
  .section-inner {
    padding: 60px 40px 25px;
  }
  .about__img {
    height: 200px;
    margin-bottom: 0;
    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
}
/* button */
.button.button-blue {
  background: #0065ff;
  color: #fff;
  height: 50px;
  line-height: 50px;
  padding: 0 22px;
  border-radius: 24px;
  cursor: pointer;
  &:hover {
    background: #0065B0;
    color: #fff;
  }
}
/* pythonPage-target target */
.pythonPage-target.target {
  margin-bottom: 40px;
  ol {
	  li {
		  list-style-position: inside;
	  }
  }
}
/* pythonForm */
.pythonForm {
  .h2 {
    margin-bottom: 0;
  }
  .sliderRobo {
    top: 0;
  }
}
/* space_requirements.python */
.space_requirements.python {
  .section-inner {
    padding: 40px 65px 20px;
  }
}

.python__includes, .kosmos-target-audience {
	.audience_bottom_text, .kosmos_audience_bottom_text {
		width: 90%;
		max-width: 600px;
		margin: 0 auto;
		font-size: 16px;
		line-height: 1.2;
		color: #848383;
		text-align: center;
		margin-top: 30px;
	}
}
